import React from "react";
import { ThemeProvider as MUIThemeProvider, createTheme } from "@mui/material/styles";
import { ICustomerPortalTheme, SECTION } from "@smartrr/shared/entities/CustomerPortalTheme";
import { AquariusHeader } from "../../../components/PreviewComponents/AquariusHeader";
import { BannerText, InnerWrapper, MarketingBanner } from "../styles";
import { RequireNilFields } from "@smartrr/shared/utils/RequireNilFields";
import { IInstaAllPostData } from "@smartrr/shared/entities/Instagram";
import { Container } from "@mui/material";
import MUIBox from "@mui/material/Box";
import MUIStack from "@mui/material/Stack";
import { OALoyalty } from "../../../components/PreviewComponents/OALoyalty";
import { OAOrderHistory } from "../../../components/PreviewComponents/OAOrderHistory";
import { OASubscription } from "../../../components/PreviewComponents/OASubscription";
import { OASettings } from "../../../components/PreviewComponents/Settings";
import { Instagram } from "../../../components/PreviewComponents/Instagram";
import { Trending } from "../../../components/PreviewComponents/Trending";
import { ThemingStoreAccess } from "../../../store";
import { useProductImageWithName } from "../hooks";

interface AquariusContentProps {
  themeValues: RequireNilFields<ICustomerPortalTheme>;
  selectedPreview: string;
  postData: IInstaAllPostData | undefined;
}

export const AquariusContent = ({ themeValues, selectedPreview, postData }: AquariusContentProps) => {
  const { setPostData } = ThemingStoreAccess.useActions();
  const {
    purchasableImage: fpImage,
    purchasableName: fpName,
    variantName: fpVariantName,
  } = useProductImageWithName();

  const cpTheme = createTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          mdsm: 700,
        },
      },
      typography: {
        // Only supporting variants outlined in designs
        subtitle1: undefined,
        subtitle2: undefined,
        caption: undefined,
        overline: undefined,
      },
    },
    themeValues!
  );
  return (
    <MUIThemeProvider theme={cpTheme}>
      <AquariusHeader mobilePreview={selectedPreview === "mobile"} themeValues={themeValues} />
      <InnerWrapper background={themeValues.modernPageBackground}>
        <Container>
          {!!themeValues.modernThemeSettings.sections.banner.display &&
            !!themeValues.modernThemeSettings.sections.banner.text && (
              <MUIBox pt="20px">
                <MarketingBanner>
                  <BannerText
                    dangerouslySetInnerHTML={{ __html: themeValues.modernThemeSettings.sections.banner.text }}
                  />
                </MarketingBanner>
              </MUIBox>
            )}

          <MUIStack spacing="20px">
            {[
              <React.Fragment
                key={
                  !!themeValues.modernThemeSettings.sectionsOrder &&
                  themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.SUBSCRIPTION)
                }
              >
                {!!themeValues?.modernThemeSettings?.sections.subscriptions.display && (
                  <OASubscription
                    productName={fpName}
                    productImage={fpImage}
                    mobilePreview={selectedPreview === "mobile"}
                  />
                )}
              </React.Fragment>,
              <React.Fragment
                key={
                  !!themeValues.modernThemeSettings.sectionsOrder &&
                  themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.ORDERHISTORY)
                }
              >
                {!!themeValues.modernThemeSettings.sections.orderHistory.display && (
                  <OAOrderHistory
                    productImage={fpImage}
                    productName={fpName}
                    mobilePreview={selectedPreview === "mobile"}
                  />
                )}
              </React.Fragment>,
              <React.Fragment
                key={
                  !!themeValues.modernThemeSettings.sectionsOrder &&
                  themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.SETTINGS)
                }
              >
                {!!themeValues.modernThemeSettings.sections.settings.display && (
                  <OASettings mobilePreview={selectedPreview === "mobile"} />
                )}
              </React.Fragment>,
              <React.Fragment
                key={
                  !!themeValues.modernThemeSettings.sectionsOrder &&
                  themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.INSTAGRAM)
                }
              >
                {themeValues.modernThemeSettings.sections.instagram?.display ? (
                  <Instagram postData={postData} setPostData={setPostData} />
                ) : null}
              </React.Fragment>,
              <React.Fragment
                key={
                  !!themeValues.modernThemeSettings.sectionsOrder &&
                  themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.LOYALTY)
                }
              >
                {!!themeValues.modernThemeSettings.sections.rewards &&
                  !!themeValues.modernThemeSettings.sections.rewards.display && (
                    <OALoyalty
                      productName={fpName}
                      productImage={fpImage}
                      variantName={fpVariantName}
                      mobilePreview={selectedPreview === "mobile"}
                    />
                  )}
              </React.Fragment>,
              <React.Fragment
                key={
                  !!themeValues.modernThemeSettings.sectionsOrder &&
                  themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.TRENDING)
                }
              >
                {!!themeValues.modernThemeSettings.sections.trending &&
                  !!themeValues.modernThemeSettings.sections.trending.display && (
                    <Trending
                      mobilePreview={selectedPreview === "mobile"}
                      previewProduct={{ title: fpName, img: fpImage }}
                    />
                  )}
              </React.Fragment>,
            ]
              .map(section => section)
              .filter(section => Number(section.key) >= 0) // catching any sections that have been removed from sectionOrder but are still rendering their preview sections
              .sort((a, b) => Number(a.key) - (b && Number(b.key)))}
          </MUIStack>
        </Container>
      </InnerWrapper>
    </MUIThemeProvider>
  );
};

import { Page } from "@shopify/polaris";
import { IShopifyGetThemesResponse, getShopifyThemes } from "@smartrr/shared/shopifyRest/theme";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useRestClient } from "@vendor-app/app/AdminRoute/components/auth/RestProviderWrapper";
import { ThemingStoreAccess } from "./store";

import { Theming } from "./sections/Main";
import { Spinner } from "../components/elements/Spinner";
import { useThemeSidebarSections } from "./sections/Main/hooks";
import { defaultCustomerPortalThemeConfig } from "@smartrr/shared/themes/defaultThemeConfig";
const PageWrapper = styled.div`
  & > .Polaris-Page--fullWidth {
    padding: 0;
  }
  & > * .Polaris-Page__Content {
    margin-top: 0;
  }
`;

export function AdminThemingRoute(): JSX.Element {
  const restClient = useRestClient();
  const isThemeLoading = ThemingStoreAccess.useIsLoading();
  const currentThemeValues = ThemingStoreAccess.useCurrentThemeValues();
  const { get: getCustomerPortalTheme, updateDefaultThemeValues } = ThemingStoreAccess.useActions();
  const { addToast } = useToast();

  const [shopifyThemes, setShopifyThemes] = useState<IShopifyGetThemesResponse["themes"]>([]);
  const [mainThemeLoading, setMainThemeLoading] = useState(true);

  const getMainThemeId = useCallback(async () => {
    const res = await getShopifyThemes(restClient);

    if (res.type === "success") {
      setShopifyThemes(res.body.themes || []);
    }

    if (res.type === "error" || !res.body.themes?.find(t => t.role === "main")) {
      addToast("Error getting main theme id");
      setMainThemeLoading(false);
      return;
    }

    setMainThemeLoading(false);
  }, []);

  useEffect(() => {
    getCustomerPortalTheme();
    getMainThemeId();
  }, []);

  const { updatedSectionOrder, updatedSectionValues } = useThemeSidebarSections(
    currentThemeValues ?? defaultCustomerPortalThemeConfig
  );

  useEffect(() => {
    if (currentThemeValues) {
      updateDefaultThemeValues("modernThemeSettings", {
        ...currentThemeValues.modernThemeSettings,
        sections: updatedSectionValues,
        sectionsOrder: updatedSectionOrder,
      });
    }
  }, []);

  if (!currentThemeValues) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <PageWrapper>
        <Page fullWidth>
          <React.Fragment>
            {isThemeLoading || mainThemeLoading ? (
              <Spinner />
            ) : (
              <Theming themes={shopifyThemes} currentThemeValues={currentThemeValues} />
            )}
          </React.Fragment>
        </Page>
      </PageWrapper>
    </React.Fragment>
  );
}
